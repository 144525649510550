import Block from '@/components/Block'
import { Stack } from '@/components/Layout'
import Text from '@/components/Text'
import PageLayout from '@/compositions/PageLayout'
import type { GetStaticProps, NextPage } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'

const PageNotFound: NextPage = () => {
  const { t } = useTranslation('common')
  return (
    <PageLayout>
      <Head>
        <title>{t('error404')}</title>
      </Head>
      <Block theme="white">
        <Stack y alignItems="center" justifyContent="center" className="ta-c mvh-50">
          <Text tag="h3" size="sm" weight="md">
            {t('error404Description')}
          </Text>
        </Stack>
      </Block>
    </PageLayout>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale = 'en-US' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default PageNotFound
