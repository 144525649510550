export const root = "r12k9g2u";
export const primary = "peinnp9";
export const secondary = "s1w0qp4d";
export const tertiary = "t1af2vcu";
export const clear = "c2u4t1c";
export const white = "w1hs29zy";
export const black = "b7eza0n";
export const guide = "gxh6ltx";
export const spacing_sm = "s1oxgj0f";
export const spacing_md = "sqvs8ws";
export const spacing_lg = "s1b53jux";
/* phantom tether is a hack to position an element
 * fixed AND in place, so it will tether from it's current position
 * This is useful for things like the day scroller
 * in the schedule view, though could have other applications too
 */
export const phantomTether = "p1dwtsrq";
export const phantomBlock = "p1rcz8tx";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");