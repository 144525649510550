import { UnitSize } from '@/constants/measured-scope'
import classNames from 'classnames/bind'
import debounce from 'lodash/debounce'
import React, { useEffect, useRef } from 'react'
import * as styles from './style'
const cx = classNames.bind(styles)
export type Theme = 'primary' | 'secondary' | 'tertiary' | 'white' | 'black' | 'clear' | 'guide'
export interface Props {
  theme?: Theme
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  spacing?: UnitSize
  phantomTether?: boolean
  backgroundImage?: boolean
  id?: string
}

const Block = ({
  theme = 'clear',
  children,
  className = '',
  spacing = 'sm',
  phantomTether,
  id,
}: Props): JSX.Element => {
  const mainRef = useRef<HTMLDivElement>(null)
  const height = useRef(0)
  // Hack to force re-render so tethered element positions can be redetermined.
  // Will only update for phantomBlock components, and whilst there may be a few on a page
  // the debounce should ensure this is a fairly lightweight solution
  const [renderKey, setRenderKey] = React.useState(0)

  // Recalculate the height of the <Block /> when the window resizes, and
  // change the render key to force a re-render, enabling CSS to take control once more
  useEffect(() => {
    let unmounted = false
    if (phantomTether) {
      const _resizeHandler = (): void => {
        height.current = mainRef.current?.clientHeight || 0
        if (!unmounted) {
          setRenderKey(renderKey + 1)
        }
      }
      const resizeHandler = debounce(_resizeHandler, 1000)
      window.addEventListener('resize', resizeHandler)
      return (): void => {
        unmounted = true
        window.removeEventListener('resize', resizeHandler)
      }
    }
  }, [phantomTether, renderKey])

  useEffect(() => {
    let unmounted = false
    if (!phantomTether && !mainRef?.current) return
    if (!unmounted) {
      height.current = mainRef.current?.clientHeight || 0
    }
    return () => {
      unmounted = true
    }
  }, [])

  const classes = cx({
    root: true,
    [styles[`spacing_${spacing}`]]: true,
    [styles[`${theme}`]]: true,
    [className]: true,
    phantomTether,
  })

  return (
    <React.Fragment key={renderKey}>
      <section id={id} ref={mainRef} className={classes}>
        {children}
      </section>
      {phantomTether && <div style={{ height: height?.current }} className={styles.phantomBlock}></div>}
    </React.Fragment>
  )
}
export default Block
